//TODO 
// setup global onclick handlers that check for data-segment-event attributes and then sends the respective
// event. Maybe include the hx-vals, too

import * as Sentry from '@sentry/browser';

Sentry.init({
   // TODO configure
   dsn: undefined,
});

// TODO inject twitch info from jwt
